import {
  Svg,
  type SvgProps,
} from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';
import type { FunctionComponent } from 'react';

export const ArrowNext: FunctionComponent<SvgProps> = (props) => (
  <Svg optimizationId="iconsSystemArrowNext" viewBox="0 0 24 24" {...props}>
    <path
      d="M5.418 11h11.17l-4.88-4.88c-.39-.39-.39-1.03 0-1.42a.996.996 0 011.41 0l6.59 6.59c.39.39.39 1.02 0 1.41l-6.59 6.59a.996.996 0 11-1.41-1.41l4.88-4.88H5.417c-.55 0-1-.45-1-1s.45-1 1-1z"
      fill="currentColor"
    />
  </Svg>
);

ArrowNext.displayName = 'ArrowNext';
