import classnames from 'classnames';
import type { FunctionComponent, SVGAttributes } from 'react';
import { useInView } from 'react-intersection-observer';

import { Image } from '@/domains/core/Image/Image';
import {
  adviceByManomano,
  sustainabilityText,
} from '@/domains/digitalAdvice/components/AdviceTag/translations';
import type { Sponsor } from '@/domains/digitalAdvice/GuideCard/GuideCard.model';
import { TipsheetCardVariant } from '@/domains/digitalAdvice/TipsheetCard/constants';

import Green from './img/leaf.svg';
import Light from './img/manomano.blue.svg';
import Accent from './img/manomano.white.svg';

import styles from './AdviceTag.module.scss';

export interface AdviceTagProps {
  className?: string;
  size?: 'small' | 'medium';
  text?: string | string[];
  variant?: TipsheetCardVariant;
  sponsor?: Sponsor | null;
}

export const AdviceTag: FunctionComponent<AdviceTagProps> = ({
  className,
  size = 'medium',
  text,
  variant = TipsheetCardVariant.ACCENT,
  sponsor,
}) => {
  const [ref, hasBeenDisplayed] = useInView({ triggerOnce: true });
  const defaultText =
    variant === TipsheetCardVariant.GREEN
      ? sustainabilityText
      : adviceByManomano;

  const icons: Record<
    TipsheetCardVariant,
    FunctionComponent<SVGAttributes<SVGElement>>
  > = {
    [TipsheetCardVariant.ACCENT]: Accent,
    [TipsheetCardVariant.GREEN]: Green,
    [TipsheetCardVariant.LIGHT]: Light,
  };
  const Icon = icons[variant];

  return (
    <div
      ref={ref}
      className={classnames(
        hasBeenDisplayed && styles.badge,
        sponsor ? styles.sponsor : styles[variant],
        styles[size],
        className,
      )}
      data-testid="advice-tag"
    >
      {text || defaultText}
      {sponsor ? (
        <Image
          src={sponsor.logo}
          alt={sponsor.name}
          height={16} // This size is for the gardena logo
          width={70}
          className={styles.icon}
        />
      ) : (
        <Icon className={styles.icon} />
      )}
    </div>
  );
};
