import { forwardRef, MouseEventHandler } from 'react';
import { twJoin } from 'tailwind-merge';

import { Span } from '@/core/tamagoshiTailwind/components/Typography/Typography';
import { InfoIcon } from '@/core/tamagoshiTailwind/icons/system/InfoIcon';

type SponsoredLabelProps = {
  /** The label to display */
  label: string;
  /** The click handler */
  onClick: MouseEventHandler<HTMLButtonElement>;
  /** Variant to use */
  variant?: 'dark' | 'light';
};

/**
 * Component to display a clickable sponsored label.
 *
 * The style of the label is based on the provided `variant`.
 *
 * See the related [Figma][figma] for more details.
 *
 * [figma]: https://www.figma.com/design/KAiRlBccr6bFYmKby9wQrR?node-id=25070-106929
 */
export const SponsoredLabel = forwardRef<
  HTMLButtonElement,
  SponsoredLabelProps
>(({ label, onClick, variant = 'dark' }, ref) => (
  <button
    className={twJoin(
      'flex h-[20px] items-center rounded-tag',
      variant === 'dark' && 'text-foundation-primary',
      variant === 'light' &&
        'bg-foundation-overlayHover text-foundation-inverted',
    )}
    ref={ref}
    onClick={onClick}
  >
    <Span scale="tiny" className="desktop:text-small">
      {label}
    </Span>
    <InfoIcon className="size-[12px]" />
  </button>
));

SponsoredLabel.displayName = 'SponsoredLabel';
