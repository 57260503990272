import classnames from 'classnames';
import {
  ImgHTMLAttributes,
  SyntheticEvent,
  type FunctionComponent,
} from 'react';
import { useDispatch } from 'react-redux';

import { Image } from '@/core/Image/Image';
import { Link } from '@/core/routing/components/Link';
import { ArrowNext as ArrowNextIcon } from '@/core/tamagoshi/icons/ArrowNext';
import { SponsoredLabel } from '@/core/tamagoshiTailwind/components/Label/SponsoredLabel';
import {
  Div,
  Typography,
} from '@/core/tamagoshiv2/components/Typography/Typography';
import { useTrackViewedElement } from '@/core/tracking/hooks/useTrackViewedElement';
import { Gtm } from '@/core/tracking/utils/Gtm';
import { AdviceTag } from '@/digitalAdvice/components/AdviceTag/AdviceTag';
import { type Sponsor } from '@/digitalAdvice/GuideCard/GuideCard.model';
import { TipsheetCardVariant } from '@/digitalAdvice/TipsheetCard/constants';
import { attachQueryParams } from '@/domains/digitalAdvice/utils/attachQueryParams';
import { displayAdvertiserInformation } from '@/productDiscovery/CommercialAnimation/CommercialAnimation.actions';
import { createAdvertiserInformationPopupContent } from '@/productDiscovery/CommercialAnimation/utils/createAdvertiserInformationPopupContent';
import { getSponsoredLabelText } from '@/sellerAdsManagement/sponsoredLabelText/sponsoredLabelText';

import { subtitle } from './translations';

import popupStyles from '@/productDiscovery/CommercialAnimation/components/AdvertiserInformation/AdvertiserInformationPopup.module.scss';
import styles from './TipsheetCard.module.scss';

/** Make source attributes for an image element with `path` prefix. */
export const makeSourceSets = (
  path: string,
  isContentFulImage: boolean = false,
): Pick<ImgHTMLAttributes<HTMLImageElement>, 'sizes' | 'src' | 'srcSet'> => {
  // NOTE Under a certain threshold (see stylesheet) the image is using
  //      the full 100vw. Above the threshold the image width can vary
  //      between 33vw and 50vw. We assume 50vw to accomodate for worst
  //      case scenario and simplify the code.
  const sizes = '(min-width: 800px) 50vw';
  // NOTE Build the array of sources based on available width. The rendered area
  //      ratio varies between 3:1 and 4:1 across resolutions. We assume 4:1 for
  //      simplicity. Widths above the threshold add support for higher DPI
  //      devices.
  //      Supported widths: 400, 600, ..., 1600
  const srcSet = Array.from({ length: 7 }, (_, i) => 400 + i * 200)
    .map(
      (it) =>
        `${attachQueryParams({
          urlString: path,
          params: { w: it, h: Math.ceil(it / 3), fit: 'cover', quality: 60 },
          densityDescriptor: `${it}w`,
        })}`,
    )
    .join(',');
  return {
    sizes,
    src: attachQueryParams({
      urlString: path,
      params: { w: 584, h: 328, fit: 'cover', quality: 60 },
    }),
    srcSet,
  };
};

export interface TipsheetCardProps {
  className?: string;
  imageAlt?: string;
  imageSrc: string;
  layout?: 'horizontal' | 'vertical';
  onViewed?: () => void;
  /** Optional position index for when the card is contained in a carousel. */
  positionIndex?: number;
  title: string;
  url: string;
  variant?: TipsheetCardVariant;
  sponsor?: Sponsor | null;
}

export const TipsheetCard: FunctionComponent<TipsheetCardProps> = ({
  className,
  imageAlt,
  imageSrc,
  layout = 'vertical',
  onViewed,
  positionIndex,
  title,
  url,
  variant = TipsheetCardVariant.ACCENT,
  sponsor,
}) => {
  const dispatch = useDispatch();
  const handleClick = () =>
    Gtm.push({
      amp_event_name: 'Click on tipsheet card',
      e_action: 'Click on tipsheet card',
      e_category: 'navigation',
      e_label: title,
      event: 'interaction_click on tipsheet card',
      tipsheet_name: title,
      tipsheet_url: url,
      is_sponsored: !!sponsor,
      ...(positionIndex !== undefined && {
        // NOTE Start at 1
        tipsheet_position: positionIndex + 1,
      }),
    });

  /** Map the card variant to the corresponding contrasted advice tag. */
  const adviceTagVariant = (
    {
      [TipsheetCardVariant.ACCENT]: TipsheetCardVariant.LIGHT,
      [TipsheetCardVariant.GREEN]: TipsheetCardVariant.GREEN,
      [TipsheetCardVariant.LIGHT]: TipsheetCardVariant.ACCENT,
    } as Record<TipsheetCardVariant, TipsheetCardVariant>
  )[variant];

  const [ref] = useTrackViewedElement(() => {
    Gtm.push({
      amp_event_name: 'Display tipsheet card',
      e_action: 'Display tipsheet card',
      e_category: 'advice',
      event: 'interaction_display tipsheet card',
      event_trigger: '%category_name%',
      is_sponsored: !!sponsor,
    });
    onViewed?.();
  });

  const isContentFulImage = imageSrc.includes('ctf');

  const imageProps =
    layout === 'horizontal'
      ? makeSourceSets(imageSrc, isContentFulImage)
      : {
          src: attachQueryParams({
            urlString: imageSrc,
            params: { w: 584, h: 328, fit: 'cover', quality: 60 },
          }),
        };
  const onAdvertiserLabelClicked = async (e: SyntheticEvent) => {
    if (sponsor) {
      e.preventDefault();
      const adInfo = {
        sponsor: sponsor.name,
        advertiser: sponsor.name,
        userSegments: true,
        dsaPopup: !!(sponsor.name || sponsor.name),
        content: createAdvertiserInformationPopupContent(
          sponsor.name,
          sponsor.name,
          true,
          popupStyles.content,
        ),
      };
      dispatch(displayAdvertiserInformation(adInfo));
    }
  };
  return (
    <Link
      ref={ref}
      className={classnames(styles.root, styles[variant], className, {
        [styles.horizontal]: layout === 'horizontal',
      })}
      href={url}
      onClick={handleClick}
    >
      <div className={styles.header}>
        <Image
          alt={imageAlt || title}
          className={styles.image}
          width="100%"
          height="inherit"
          {...imageProps}
        />
        {sponsor && (
          <div className={styles.sponsoredLabel}>
            <SponsoredLabel
              label={getSponsoredLabelText()}
              onClick={onAdvertiserLabelClicked}
            />
          </div>
        )}
      </div>
      <div className={styles.body}>
        <AdviceTag
          className={styles.tag}
          variant={adviceTagVariant}
          sponsor={sponsor}
        />
        <Typography className={styles.title} type="title" variant="h3">
          {title}
        </Typography>
        <Div className={styles.footer} type="semiBold">
          {subtitle}
          <ArrowNextIcon className={styles.icon} />
        </Div>
      </div>
    </Link>
  );
};

TipsheetCard.displayName = 'TipsheetCard';
