interface Params {
  urlString: string;
  params: Record<string, string | number>;
  densityDescriptor?: string;
}

export const attachQueryParams = ({
  urlString,
  params,
  densityDescriptor,
}: Params) => {
  const isContentFulImage = urlString.includes('ctf');
  const url = new URL(urlString);

  Object.entries(params).forEach(([key, value]) => {
    if (
      !isContentFulImage ||
      (key !== 'fit' && key !== 'quality' && key !== 'format')
    ) {
      url.searchParams.set(key, value.toString());
    }
  });

  return densityDescriptor
    ? `${url.toString()} ${densityDescriptor}`
    : url.toString();
};
